<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          v-if="! isSubfilter"
          cols="3"
        >
          <v-text-field
            @input="editFilterKey"
            :value="data.filter_key"
            :label="$t('key')"
            :rules="[v => !!v || $t('form_rules.mandatory')]"
            outlined
            clearable
            required
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="2"
        >
          <v-select
            @change="editFilterOperator"
            :items="logicalOperatorsList"
            :value="data.logical_operator"
            :label="$t('logical_operator')"
            :rules="[v => !!v || $t('form_rules.mandatory')]"
            itemText="label"
            itemValue="value"
            outlined
            required
            dense
          ></v-select>
        </v-col>
        <v-col 
          v-if="isSubfilter"
          cols="1"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="$emit('remove-subfilter')"
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                depressed
                fab
                dark
                x-small
                color="grey darken-2"
              >
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
            </template>

            <span>{{ $t('remove_subfilter') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      
      <FieldConditionRow 
        v-for="(field, index) in data.field"
        :key="index + 'field'"
        :data="field"
        @updated-condition="editFieldCondition($event, index)"
        @remove-condition="removeFieldCondition(index)"
      />

      <GroupConditionRow 
        v-for="(group, index) in data.group"
        :key="index + 'group'"
        :data="group"
        @updated-condition="editGroupCondition($event, index)"
        @remove-condition="removeGroupCondition(index)"
      />

      <v-card>
        <FilterRow
          v-for="(filter, index) in data.subfilter"
          :key="index + 'filter'"
          :data="filter"
          @updated-filter="updatedSubfilter($event, index)"
          @remove-subfilter="removeSubfilter(index)"
          is-subfilter
        />
      </v-card>
    </v-card-text>

    <v-card-actions>
      <v-menu
        offset-y
        close-on-content-click
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mx-2"
            color="primary"
            fab
            dark
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(action, index) in actions"
            :key="index"
            @click="callActionMethod(action)"
            link
          >
            <v-list-item-title>{{ $t(action.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>

  </v-card>
  
</template>

<script>

import FieldConditionRow from '@/components/node/filter/FieldConditionRow.vue'
import GroupConditionRow from '@/components/node/filter/GroupConditionRow.vue'

export default {
  name: 'FilterRow',

  components: {
    FieldConditionRow,
    GroupConditionRow,
  },

  props: {
    isSubfilter: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object
    }
  },

  data() {
    return {
      logicalOperatorsList: [
        {
          label: 'AND',
          value: 'and'
        },
        {
          label: 'OR',
          value: 'or'
        },
      ],
      actions: [
        {
          title: 'group_condition',
          callMethod: 'addGroupCondition',
        },
        {
          title: 'field_condition',
          callMethod: 'addFieldCondition',
        },
        {
          title: 'subfilter',
          callMethod: 'addSubfilter',
        },
      ],
    }
  },

  methods: {
    callActionMethod(action, event) {
      this[action.callMethod](event)
    },

    addSubfilter() {
      const newSubfilter = {
        built_in: this.data.tenant_id ? false : true,
        field: [],
        filter_key: `sub-${new Date().getTime()}`,
        filter_parent: this.data.filter_id,
        group: [],
        logical_operator: 'and',
        subfilter: [],
        tenant_id: this.data.tenant_id
      }

      const filter = {...this.data}
      filter.subfilter.push(newSubfilter)

      this.$emit('updated-filter', filter)
    },

    addFieldCondition() {
      const newFieldCondition = {
        field_id: null,
        operator: '=',
        value: null,
      }

      const filter = {...this.data}
      filter.field.push(newFieldCondition)

      this.$emit('updated-filter', filter)
    },

    editFieldCondition(condition, index) {
      const filter = {...this.data}

      filter.field[index] = {...condition}

      this.$emit('updated-filter', filter)
    },

    removeFieldCondition(index) {
      const filter = {...this.data}

      filter.field.splice(index, 1);

      this.$emit('updated-filter', filter)
    },

    addGroupCondition() {
      const newGroupCondition = {
        group_id: null,
        operation: 'allow',
      }

      const filter = {...this.data}
      filter.group.push(newGroupCondition)

      this.$emit('updated-filter', filter)
    },

    editGroupCondition(condition, index) {
      const filter = {...this.data}

      filter.group[index] = {...condition}

      this.$emit('updated-filter', filter)
    },

    removeGroupCondition(index) {
      const filter = {...this.data}

      filter.group.splice(index, 1);

      this.$emit('updated-filter', filter)
    },

    updatedSubfilter(subfilter, index) {
      const filter = {...this.data}

      filter.subfilter[index] = {...subfilter}

      this.$emit('updated-filter', filter)
    },

    removeSubfilter(index) {
      const filter = {...this.data}

      filter.subfilter.splice(index, 1);

      this.$emit('updated-filter', filter)
    },

    editFilterKey(filter_key) {
      const filter = {...this.data, filter_key}

      this.$emit('updated-filter', filter)
    },

    editFilterOperator(logical_operator) {
      const filter = {...this.data, logical_operator}

      this.$emit('updated-filter', filter)
    },
  },
}

</script>
