<template>
  <DefaultCrudView
    :breadcrumbsItems="breadcrumbs"
    containerMedium
  >
    <template slot="content">
      <v-form 
        ref="form"
      >
        <v-card
          :loading="loading"
        >
          <v-card-text>
            <FilterRow 
              :data="data"
              @updated-filter="updatedFilter($event)"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              @click.stop="save"
              :loading="loading"
              color="primary"
              ripple
            >
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }
</style>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import FilterRow from '@/components/node/filter/FilterRow.vue'

import AppActions from '@/store/app/actions-types'
import FieldActions from '@/store/operation/field/actions-types'
import GroupActions from '@/store/core/group/actions-types'
import FilterActions from '@/store/operation/filter/actions-types'
import FilterMutations from '@/store/operation/filter/mutations-types'

export default {
  name: "FilterSettings",

  components: {
    DefaultCrudView,
    FilterRow,
  },

  computed: {
    ...mapGetters('app', [
      'getIconForOperationSettingsItem'
    ]),

    breadcrumbs() {
      return [
        {
          text: this.$t('filters'),
          prependIcon: this.getIconForOperationSettingsItem('filters'),
          iconColor: 'primary',
          to: {
            name: "FilterSettings"
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: this.data['filter_key'] ?? '',
          disabled: true,
        }
      ]
    },
  },

  data() {
    return {
      loading: true,
      data: {},
    }
  },

  mounted() {
    const filterId = this.$route.params.filterId

    this.fetchData(filterId)
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('operation/field', [
      FieldActions.GET_TENANT_FIELDS,
    ]),

    ...mapActions('core/group', [
      GroupActions.GET_ALL_GROUPS,
    ]),

    ...mapActions('operation/filter', [
      FilterActions.GET_FILTER_FULL,
      FilterActions.SAVE_FILTER_FULL,
    ]),

    ...mapMutations('operation/filter', [
      FilterMutations.SET_FIELDS_LIST,
      FilterMutations.SET_GROUPS_LIST,
    ]),

    fetchData(filterId) {
      this.loading = true

      this[FilterActions.GET_FILTER_FULL](filterId)
      .then((result) => {
        this.data = {...result}

        const tenantId = result.tenant_id

        const fieldPromise = this[FieldActions.GET_TENANT_FIELDS](tenantId)
        fieldPromise.then((fields) => {
          this[FilterMutations.SET_FIELDS_LIST](fields)
        })

        const options = {'filter[tenant]' : tenantId}

        const groupPromise = this[GroupActions.GET_ALL_GROUPS](options)
        groupPromise.then((groups) => {
          this[FilterMutations.SET_GROUPS_LIST](groups.items)
        })

        Promise.all([fieldPromise, groupPromise])
        .then(() => this.loading = false)
      })
      .catch(() => this.loading = false)
    },

    save() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true

      const filterId = this.$route.params.filterId

      this[FilterActions.SAVE_FILTER_FULL]({ filterId, params: this.data })
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('filter_updated'))

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    updatedFilter(filter) {
      this.data = {...filter}
    },
  }
}

</script>