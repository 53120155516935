<template>
  <v-row>
    <v-col cols="2">
      <v-text-field
        value="Field"
        :label="$t('type')"
        outlined
        readonly
        disabled
        dense
      ></v-text-field>
    </v-col>
    <v-col>
      <v-select
        @change="updateField"
        :items="fieldsList"
        :value="data.field_id"
        :label="$t('field')"
        :rules="[v => !!v || $t('form_rules.mandatory')]"
        itemText="field_key"
        itemValue="field_id"
        outlined
        required
        dense
      ></v-select>
    </v-col>
    <v-col cols="2">
      <v-select
        @change="updateOperator"
        :items="logicalOperatorsList"
        :value="data.operator"
        :label="$t('operator')"
        :rules="[v => !!v || $t('form_rules.mandatory')]"
        outlined
        required
        dense
      ></v-select>
    </v-col>
    <v-col>
      <v-text-field
        @input="updateValue"
        :value="data.value"
        :label="$t('value')"
        :rules="[v => !!v || $t('form_rules.mandatory')]"
        outlined
        clearable
        required
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="1">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="$emit('remove-condition')"
            v-bind="attrs"
            v-on="on"
            class="mx-2"
            depressed
            fab
            dark
            x-small
            color="grey darken-2"
          >
            <v-icon dark>
              mdi-minus
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('remove_condition') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
  
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'FieldConditionRow',

  props: {
    data: {
      type: Object
    }
  },

  data() {
    return {
      logicalOperatorsList: [
        '=',
        '<>',
        '>',
        '<',
        '>=',
        '<=',
      ],
    }
  },

  computed: {
    ...mapState({
      fieldsList: state => state.operation.filter.fieldsList,
    }),
  },

  methods: {
    updateField(field_id) {
      const condition = {...this.data, field_id}

      this.$emit('updated-condition', condition);
    },

    updateOperator(operator) {
      const condition = {...this.data, operator}

      this.$emit('updated-condition', condition);
    },

    updateValue(value) {
      const condition = {...this.data, value}

      this.$emit('updated-condition', condition);
    },
  },
}

</script>
